export var API_KEY = 'AIzaSyBDarGMpHEkYOsq-n02JWJnu_NxTXSGGx8';
export var STEPS = {
  SHOW_METHODS: 'SHOW_METHODS',
  SHOW_WIDGET: 'SHOW_WIDGET',
  REFRESH_TOKEN: 'REFRESH_TOKEN'
};
export var PAYMENTS_STATUS = {
  SUCCESS: 'success',
  REFRESH_TOKEN: 'refresh',
  DECLINE: 'decline',
  S_3DS: '3ds'
};
export var ERRORS_MESSAGE = {
  name: 'Please enter your first name',
  surname: 'Please enter your last name',
  email: 'Please enter your email',
  month: 'Enter month',
  day: 'Enter day',
  year: 'Enter year',
  address: 'Please enter your address line',
  city: 'Please enter your city',
  state: 'Please enter your state',
  zip: 'Please enter your zip',
  phone: 'Please enter your phone'
};
export var STATES = [{
  name: 'Alabama',
  code: 'AL'
}, {
  name: 'Alaska',
  code: 'AK'
}, {
  name: 'Arizona',
  code: 'AZ'
}, {
  name: 'Arkansas',
  code: 'AR'
}, {
  name: 'California',
  code: 'CA'
}, {
  name: 'Colorado',
  code: 'CO'
}, {
  name: 'Connecticut',
  code: 'CT'
}, {
  name: 'Delaware',
  code: 'DE'
}, {
  name: 'District Of Columbia',
  code: 'DC'
}, {
  name: 'Florida',
  code: 'FL'
}, {
  name: 'Georgia',
  code: 'GA'
}, {
  name: 'Hawaii',
  code: 'HI'
}, {
  name: 'Illinois',
  code: 'IL'
}, {
  name: 'Indiana',
  code: 'IN'
}, {
  name: 'Iowa',
  code: 'IA'
}, {
  name: 'Kansas',
  code: 'KS'
}, {
  name: 'Kentucky',
  code: 'KY'
}, {
  name: 'Louisiana',
  code: 'LA'
}, {
  name: 'Maine',
  code: 'ME'
}, {
  name: 'Maryland',
  code: 'MD'
}, {
  name: 'Massachusetts',
  code: 'MA'
}, {
  name: 'Michigan',
  code: 'MI'
}, {
  name: 'Minnesota',
  code: 'MN'
}, {
  name: 'Mississippi',
  code: 'MS'
}, {
  name: 'Missouri',
  code: 'MO'
}, {
  name: 'Montana',
  code: 'MT'
}, {
  name: 'Nebraska',
  code: 'NE'
}, {
  name: 'Nevada',
  code: 'NV'
}, {
  name: 'New Hampshire',
  code: 'NH'
}, {
  name: 'New Jersey',
  code: 'NJ'
}, {
  name: 'New Mexico',
  code: 'NM'
}, {
  name: 'New York',
  code: 'NY'
}, {
  name: 'North Carolina',
  code: 'NC'
}, {
  name: 'North Dakota',
  code: 'ND'
}, {
  name: 'Ohio',
  code: 'OH'
}, {
  name: 'Oklahoma',
  code: 'OK'
}, {
  name: 'Oregon',
  code: 'OR'
}, {
  name: 'Pennsylvania',
  code: 'PA'
}, {
  name: 'Rhode Island',
  code: 'RI'
}, {
  name: 'South Carolina',
  code: 'SC'
}, {
  name: 'South Dakota',
  code: 'SD'
}, {
  name: 'Tennessee',
  code: 'TN'
}, {
  name: 'Texas',
  code: 'TX'
}, {
  name: 'Utah',
  code: 'UT'
}, {
  name: 'Vermont',
  code: 'VT'
}, {
  name: 'Virginia',
  code: 'VA'
}, {
  name: 'West Virginia',
  code: 'WV'
}, {
  name: 'Wisconsin',
  code: 'WI'
}];
export var MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export var NAME_SUFFIXES = ['', 'Sr.', 'Jr.', 'I', 'II', 'III'];
export var PAGES = {
  depositMoney: 'deposit-by-money',
  depositEntries: 'winnings-to-entries',
  deposit: 'deposit',
  redeem: 'redeem',
  history: 'history'
};
export var REDEEM_FORM_HINTS = {
  phone: 'Funds will be added to the Amazon account associated with the following phone number. This phone number must be associated with an Amazon account. Or you can link the phone number to the Amazon account later, and then the gift card funds will be credited to account balance.',
  email: 'You will get your gift card in your email inbox'
};
export var TRANSACTION_STATUS_PENDING = 'pending';
export var TRANSACTION_STATUS_CLOSED = 'closed';
export var TRANSACTION_STATUS_CANCELED = 'canceled';
export var TRANSACTION_STATUS_DENIED = 'denied';
export var TRANSACTION_TYPE_IN = 'in';
export var TRANSACTION_TYPE_OUT = 'out';
export var HISTORY_TYPE_ALL = 'all';
export var HISTORY_TYPE_INPUT = 'in';
export var HISTORY_TYPE_OUTPUT = 'out';