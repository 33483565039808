import "core-js/modules/es6.array.filter.js";
export default {
  namespaced: true,
  state: function state() {
    return {
      paymentsMethods: [],
      trustlyProviders: [],
      ravelinDeviceId: '',
      lastProvider: {},
      cardProviders: [],
      activeMethod: null,
      isShowBackBtn: false
    };
  },
  getters: {
    paymentsMethods: function paymentsMethods(state) {
      return state.paymentsMethods;
    },
    trustlyProviders: function trustlyProviders(state) {
      return state.trustlyProviders;
    },
    ravelinDeviceId: function ravelinDeviceId(state) {
      return state.ravelinDeviceId;
    },
    lastProvider: function lastProvider(state) {
      return state.lastProvider;
    },
    cardProviders: function cardProviders(state) {
      return state.cardProviders;
    },
    activeMethod: function activeMethod(state) {
      return state.activeMethod;
    },
    isShowBackBtn: function isShowBackBtn(state) {
      return state.isShowBackBtn;
    }
  },
  mutations: {
    setIsShowBackBtn: function setIsShowBackBtn(state, payload) {
      state.isShowBackBtn = payload;
    },
    setActiveMethod: function setActiveMethod(state, payload) {
      state.activeMethod = payload;
    },
    setTrustlyProviders: function setTrustlyProviders(state, payload) {
      state.trustlyProviders = payload;
    },
    setPaymentsMethods: function setPaymentsMethods(state, payload) {
      state.paymentsMethods = payload;
    },
    setRavelinDeviceId: function setRavelinDeviceId(state, payload) {
      state.ravelinDeviceId = payload;
    },
    setLastProvider: function setLastProvider(state, payload) {
      state.lastProvider = payload;
    },
    setCardProviders: function setCardProviders(state, payload) {
      state.cardProviders = payload;
    },
    resetCashData: function resetCashData(state) {
      state.paymentsMethods = [];
      state.activeMethod = null;
      state.lastProvider = {};
      state.cardProviders = [];
      state.trustlyProviders = [];
    }
  },
  actions: {
    setIsShowBackBtn: function setIsShowBackBtn(_ref, payload) {
      var commit = _ref.commit;
      commit('setIsShowBackBtn', payload);
    },
    setActiveMethod: function setActiveMethod(_ref2, payload) {
      var commit = _ref2.commit;
      commit('setActiveMethod', payload);
    },
    setCardProviders: function setCardProviders(_ref3, payload) {
      var commit = _ref3.commit;
      commit('setCardProviders', payload);
    },
    setLastProvider: function setLastProvider(_ref4, payload) {
      var commit = _ref4.commit;
      commit('setLastProvider', payload);
    },
    setRavelinDeviceId: function setRavelinDeviceId(_ref5, payload) {
      var commit = _ref5.commit;
      commit('setRavelinDeviceId', payload);
    },
    setPaymentsMethods: function setPaymentsMethods(_ref6, payload) {
      var commit = _ref6.commit;
      commit('setPaymentsMethods', payload);
    },
    setTrustlyProviders: function setTrustlyProviders(_ref7, payload) {
      var commit = _ref7.commit;
      commit('setTrustlyProviders', payload);
    },
    setVisibleTrustlyProviders: function setVisibleTrustlyProviders(_ref8, payload) {
      var commit = _ref8.commit;
      var visibleProvider = payload.trustlyProviders.filter(function (method) {
        return method.tokenId !== payload.tokenId;
      });
      commit('setTrustlyProviders', visibleProvider);
    },
    resetCashData: function resetCashData(_ref9) {
      var commit = _ref9.commit;
      commit('resetCashData');
    }
  }
};