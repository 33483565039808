import Vue from 'vue';
import Vuex from 'vuex';
import cash from "./cash";
import deposit from "./deposit";
import history from "./history";
import redeem from "./redeem";
import confirmation from "./confirmation";
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    cash: cash,
    confirmation: confirmation,
    deposit: deposit,
    history: history,
    redeem: redeem
  },
  strict: process.env.DEV
});
export default store;