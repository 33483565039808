import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
var routes = [{
  path: '/cash/',
  component: function component() {
    return import(
    /* webpackChunkName: "cash3" */
    '@modules/cash/layouts/CashLayout.vue');
  },
  children: [{
    name: 'deposit-by-money',
    path: 'deposit-by-money/offer/:promoOfferPresetId?/:promoType?',
    component: function component() {
      return import(
      /* webpackChunkName: "cash-deposit-by-money" */
      '@modules/cash/components/DepositByMoney.vue');
    }
  }, {
    name: 'deposit-by-money',
    path: 'deposit-by-money/:presetId?/:offerType?',
    component: function component() {
      return import(
      /* webpackChunkName: "cash-deposit-by-money" */
      '@modules/cash/components/DepositByMoney.vue');
    }
  }, {
    name: 'verification',
    path: 'verification/',
    component: function component() {
      return import(
      /* webpackChunkName: "cash-verification" */
      '@modules/cash/components/Verification.vue');
    }
  }, {
    name: 'account',
    path: 'account/',
    component: function component() {
      return import(
      /* webpackChunkName: "cash-verification" */
      '@modules/cash/components/Account.vue');
    }
  }, {
    name: 'history',
    path: 'history/',
    component: function component() {
      return import(
      /* webpackChunkName: "cash-history" */
      '@modules/cash/components/Transactions.vue');
    }
  }, {
    name: 'winnings-to-entries',
    path: 'winnings-to-entries/',
    component: function component() {
      return import(
      /* webpackChunkName: "cash-winnings-to-entries" */
      '@modules/cash/components/WinningsToEntries.vue');
    }
  }, {
    name: 'deposit',
    path: 'deposit/',
    component: function component() {
      return import(
      /* webpackChunkName: "cash3-deposit" */
      '@modules/cash/components/Deposit.vue');
    }
  }, {
    name: 'redeem',
    path: 'redeem/',
    component: function component() {
      return import(
      /* webpackChunkName: "cash-redeem" */
      '@modules/cash/components/Redeem.vue');
    }
  }, {
    name: 'success',
    path: 'success/',
    component: function component() {
      return import(
      /* webpackChunkName: "cash-deposit-success" */
      '@modules/cash/components/DepositSuccess.vue');
    }
  }, {
    name: 'redeem-success',
    path: 'redeem-success/',
    component: function component() {
      return import(
      /* webpackChunkName: "cash-redeem-success" */
      '@modules/cash/components/RedeemSuccess.vue');
    }
  }, {
    name: 'failed',
    path: 'failed/',
    component: function component() {
      return import(
      /* webpackChunkName: "cash-deposit-failed" */
      '@modules/cash/components/DepositFailed.vue');
    }
  }, {
    name: 'pending',
    path: 'pending/',
    component: function component() {
      return import(
      /* webpackChunkName: "cash-deposit-pending" */
      '@modules/cash/components/DepositPending.vue');
    }
  }, {
    name: 'play-responsible',
    path: 'play-responsible/',
    component: function component() {
      return import(
      /* webpackChunkName: "cash-play-responsible" */
      '@modules/cash/components/PlayResponsible.vue');
    }
  }, {
    name: 'thank-you',
    path: 'thank-you/',
    component: function component() {
      return import(
      /* webpackChunkName: "cash-thank-you" */
      '@modules/cash/components/ThankYou.vue');
    }
  }]
}];
var Router = new VueRouter({
  mode: 'hash',
  routes: routes
});
Router.beforeEach(function (to, from, next) {
  if (!window.$store.getters['player/loggedIn']) {
    return false;
  }

  if (process.env.RAYGUN_API_KEY && process.env.CLIENT) {
    window.rg4js('trackEvent', {
      type: 'pageView',
      path: to.path
    });
  }

  next();
});
export default Router;