import "core-js/modules/es6.regexp.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Modal',
  inheritAttrs: true,
  props: {
    idBody: {
      type: String,
      default: null
    },
    innerScroll: {
      // Remove max-height for popup - so there will be no scroll inside.
      // Popup will be scrolling in window
      type: Boolean,
      default: null
    },
    fullscreenOnMd: {
      // Will remove some styles, so popup will not be fullscreen on mobile.
      // Useful when popup has static size (onboard popups).
      type: Boolean,
      default: null
    },
    hideClose: {
      // Hide close button
      type: Boolean,
      default: false
    },
    preset: {
      // Setting some delault options (see data presets below) and some styles (see &--preset)
      // There are 3 presets:
      // 1. complex (sign-in/up, auth bonus),
      // 2. info (onboard, races result popup),
      // 3. small (reset password)
      type: String,
      default: ''
    },
    disableOutClick: {
      type: Boolean,
      default: false
    },
    closeInBody: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      skin: '',
      presets: {
        complex: {
          skin: 'big bg--alt',
          innerScroll: false
        },
        info: {
          skin: 'big',
          innerScroll: false,
          fullscreenOnMd: false
        },
        big: {
          skin: 'big'
        },
        small: {
          skin: 'small bg--alt'
        },
        fullscreen: {
          skin: 'fullscreen bg--alt'
        }
      }
    };
  },
  computed: {
    hasTitle: function hasTitle() {
      return !!this.$slots.title;
    },
    hasFooter: function hasFooter() {
      return !!this.$slots.footer;
    },
    getPreset: function getPreset() {
      return this.preset ? this.presets[this.preset] : false;
    },
    getSkin: function getSkin() {
      var skin = this.skin;

      if (this.getPreset && this.getPreset.skin !== undefined) {
        skin += (skin ? ' ' : '') + this.getPreset.skin;
      }

      return skin ? skin.split(' ') : false;
    }
  },
  methods: {
    clickOutside: function clickOutside() {
      if (this.disableOutClick) return;
      this.close();
    },
    close: function close() {
      this.$emit('close', this.$attrs);
    },
    getSetting: function getSetting(props) {
      var value = true; // Default value

      if (this[props] !== null) {
        // If props is passed, use it
        value = this[props];
      } else if (this.getPreset && this.getPreset[props] !== undefined) {
        // Check props in presets
        value = this.getPreset[props];
      }

      return value;
    }
  }
};