export default {
  namespaced: true,
  state: function state() {
    return {
      userData: {},
      requestPhone: null,
      attemptsPlayerLimit: false,
      phoneConfirmed: false
    };
  },
  getters: {
    userData: function userData(state) {
      return state.userData;
    },
    requestPhone: function requestPhone(state) {
      return state.requestPhone;
    },
    attemptsPlayerLimit: function attemptsPlayerLimit(state) {
      return state.attemptsPlayerLimit;
    },
    phoneConfirmed: function phoneConfirmed(state) {
      return state.phoneConfirmed;
    }
  },
  mutations: {
    setUserData: function setUserData(state, payload) {
      state.userData = payload;
    },
    setRequestPhone: function setRequestPhone(state, payload) {
      state.requestPhone = payload;
    },
    setAttemptsPlayerLimit: function setAttemptsPlayerLimit(state, payload) {
      state.attemptsPlayerLimit = payload;
    },
    setPhoneConfirmed: function setPhoneConfirmed(state, payload) {
      state.phoneConfirmed = payload;
    }
  },
  actions: {
    setUserData: function setUserData(_ref, payload) {
      var commit = _ref.commit;
      commit('setUserData', payload);
    },
    setRequestPhone: function setRequestPhone(_ref2, payload) {
      var commit = _ref2.commit;
      commit('setRequestPhone', payload);
    },
    setAttemptsPlayerLimit: function setAttemptsPlayerLimit(_ref3, payload) {
      var commit = _ref3.commit;
      commit('setAttemptsPlayerLimit', payload);
    },
    setPhoneConfirmed: function setPhoneConfirmed(_ref4, payload) {
      var commit = _ref4.commit;
      commit('setPhoneConfirmed', payload);
    }
  }
};