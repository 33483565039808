import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es6.array.filter.js";
export default {
  install: function install(Vue) {
    function numberFormat(value) {
      var digitsMin = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var digitsMax = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
      var number = Number(value);
      var million = Math.pow(10, 6);
      return number.toLocaleString('ru', {
        minimumFractionDigits: digitsMin,
        // todo [Dima] - Review "digitsMin" - was added to prevent numbers like 123.00 (where ".00" are not necessary)
        maximumFractionDigits: digitsMax
      }).replace(',', '.');
    }

    Vue.filter('number', function (value, digitsMin) {
      return numberFormat(value, digitsMin);
    });
    Vue.filter('currency', function (value) {
      var currencySymbol = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '$';
      return "".concat(currencySymbol, " ").concat(numberFormat(value, 2));
    });
    Vue.prototype.numberFormat = numberFormat;
  }
};