import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
//
//
//
//
//
//
export default {
  computed: {
    isOpen: function isOpen() {
      return this.$store.getters['cash/isOpen'];
    }
  },
  watch: {
    $route: function $route(to, from) {
      if (from.path.includes('cash')) {
        window.dispatchEvent(new CustomEvent('LEAVE_CASH'));
      }

      if (to.path.includes('cash')) {// Moved to Layout
        // this.$store.dispatch('cash/setIsOpen', true);
        // document.body.style.overflow = 'hidden';
      } else {
        this.$store.dispatch('cash/setIsOpen', false);
        document.body.style.overflow = 'initial';
      }
    }
  }
};