import Vue from 'vue';
import VueFinalModal from 'vue-final-modal';
import modal from '@modules/plugins/modal';
import suspendedScreen from '@modules/plugins/suspendedScreen';
import router from "./router";
import App from "./App";
import store from "./store";
import currencyFormat from "./plugins/currencyFormat";
import { i18n } from "./i18n";

if (process.env.RAYGUN_API_KEY) {
  Vue.config.errorHandler = function (err, vm, info) {
    console.error(err);
    window.rg4js('send', {
      error: err,
      customData: [{
        info: info
      }]
    });
  };
}

var cash = new Vue({
  i18n: i18n,
  store: store,
  router: router,
  render: function render(h) {
    return h(App);
  }
}).$mount('#cash');
window.$cash = cash;
Vue.use(modal);
Vue.use(currencyFormat);
Vue.use(VueFinalModal(), {
  key: '$modal'
});
Vue.use(suspendedScreen);
export default cash;